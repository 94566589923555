import dayjs from "components/Dayjs";
import { useTrans } from "locales/hook";
import { CANARY_ORGS, DateShowType } from "./constants";

import Numeral from "numeral";
import XLSX from "xlsx";

import { DATE_RANGES_DEFAULT, DATE_RANGES_RFM, DATE_RANGES_STOCK_LOTS, } from "components/Dayjs/constants";
import { DISABLE_DISPLAY_COLUMN_PERMISSIONS } from "pages/ReportStandard/context/store/constants";
import { dayAdd } from "./date";

let appLoaded = false;
export const SetAppLoaded = () => {
  appLoaded = true;
}
export const GetAppLoaded = () => {
  return appLoaded;
}

let pushInApp = false;
export const SetPushInApp = (isPushInApp) => {
  pushInApp = isPushInApp;
}
export const GetPushInApp = () => {
  return pushInApp;
}

let isCanary = false;
export const SetEnableCanary = (orgId) => {
  if (CANARY_ORGS.includes(orgId)) {
    isCanary = true;
  }
};
export const IsCanary = () => {
  return isCanary;
};

let currencyCode = "VND";
export const SetCurrencyCode = (value) => {
  if (value !== undefined && value !== "") {
    currencyCode = value;
  }
};

export const GetCurrencyCode = () => {
  return currencyCode;
};

let daysOfWeek = {};
export const SetDaysOfWeek = (data) => {
  daysOfWeek = data;
};

export let hourSuffix = {
  AM: "AM",
  PM: "PM",
};

export const SetHourSuffix = (suffix) => {
  hourSuffix = suffix;
};

let featureList = [];

export const SetAccessFeatures = (list) => {
  featureList = list;
};

export const GetAccessFeatures = () => {
  return featureList;
};

export const PointsToCommas = (num) => {
  const parts = num.split(",");
  const numberPart = parts[0];
  const decimalPart = parts[1] || "";
  let result = numberPart.replace(/\./g, ",");
  if (decimalPart !== "") {
    result += `.${decimalPart}`;
  }
  return result;
};

export const GetCurrencyFormater = () => {
  let culture = "vi-VN";
  if (currencyCode === "USD") {
    culture = "en-US";
  }
  return Intl.NumberFormat(culture, {
    style: "currency",
    currency: currencyCode,
    maximumFractionDigits: 0,
  });
};

export const formatDate = (
  time: any,
  format: string = "YYYY-MM-DD",
  inputFormat: string = "YYYY-MM-DD HH:mm:ss",
) => {
  if (typeof time === 'undefined' || !time) return '--'

  return dayjs(time, inputFormat).format(format)
};

export const formatNumber = (
  value: any,
  includeDecimal: boolean = true,
  decimal: number = 2,
) => {
  if (includeDecimal) {
    value = parseFloat(value).toFixed(decimal);
  }
  value += "";
  const list = value.split(".");
  const prefix = list[0].charAt(0) === "-" ? "-" : "";
  let num = prefix ? list[0].slice(1) : list[0];
  let result = "";
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  if (includeDecimal && parseInt(list[1]) > 0) {
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
  }

  return `${prefix}${result}`;
};

export const FormatDayOfWeek = (date: any) => {
  return daysOfWeek[date];
};

export const FormatHourOfDay = (date: any) => {
  // let prefix = hourSuffix.AM;
  // let time = 0;

  // if (date >= 12) {
  //   prefix = hourSuffix.PM;
  //   time = date - 12;
  // } else {
  //   prefix = hourSuffix.AM;
  //   time = date;
  // }

  return `${date}:00`;
};

export const valueHandler = (type, fieldName, value, dateShowType = "") => {
  switch (type) {
    case "numberlink":
      return formatNumber(value);

    case "number":
      if (
        [
          "Qty",
          "Qty_OpeningStock",
          "Qty_ClosingStock",
          "Qty_Init_In",
          "Qty_Transfer_In",
          "Qty_Adjustment_Out",
          "Qty_Adjustment_In",
          "Qty_PurchaseOrder_In",
          "Qty_SaleOrderRestock_In",
          "Qty_Transfer_Out",
          "Qty_SaleOrderFulfill_Out",
          "Qty_PurchaseOrderReturn_Out",
          "Qty_OnHand",
          "Qty_Commited",
          "Qty_Available",
          "Qty_Incoming",
          "AvgQuantityPerOrder",
          "Qty_SoldPerDay",
          "Quantity",
          "ReturnQuantity",
          "Qty_Availability",
        ].includes(fieldName)
      ) {
        let number = Math.round(Number(value) * 1000);
        let decimal = 0;
        if (Math.abs(number) % 1000 > 0) {
          decimal = 1;
          if (Math.abs(number) % 100 > 0) {
            decimal = 2;
            if (Math.abs(number) % 10 > 0) {
              decimal = 3;
            }
          }
        }
        return formatNumber(number / 1000, true, decimal);
      } else if (fieldName === "Qty_DayInventoryRemaining") {
        if (value === "--") {
          return "--";
        } else {
          return formatNumber(Math.round(Number(value)));
        }
      }

      return formatNumber(Math.round(Number(value)));

    case "money":
      if (currencyCode === "USD") {
        return "$" + formatNumber(Math.round(Number(value)));
      }
      return formatNumber(Math.round(Number(value))) + " đ";

    case "percent":
      if (value === "--") {
        return "--"
      }

      return formatNumber(Number(value * 100)) + "%";

    case "timestamp":
      if (!value || !value.length) return '--';

      if (fieldName === "Month") {
        return FormatDateTimeCustom(value, "MM/YYYY");
      } else if (fieldName === "Year") {
        return FormatDateTimeCustom(value, "YYYY");
      } else if (fieldName === "Hour") {
        return FormatDateTimeCustom(value, "DD/MM/YYYY - HH:mm");
      } else if (fieldName === "HourOfDay") {
        return FormatDateTimeCustom(value, "HH:mm");
      } else if (fieldName === "Quarter") {
        var date = new Date(value);
        return `Q${getQuarter(date)}-${FormatDateTimeCustom(value, "YYYY")}`;
      } else {
        if (
          [DateShowType.day.toString(), DateShowType.month.toString()].includes(
            dateShowType,
          )
        ) {
          return FormatDateTimeCustom(value, "DD/MM");
        }
        return FormatDateTimeCustom(value, "DD/MM/YYYY");
      }

    case "dayofweek": {
      return FormatDayOfWeek(value);
    }

    case "hourofday": {
      return FormatHourOfDay(value);
    }

    default:
      return value || "--";
  }
};

export const getQuarter = (date = new Date()) => {
  return Math.floor(date.getMonth() / 3 + 1);
};

export const linkTypeInTable = (fieldName) => {
  switch (fieldName) {
    case "DiscountId":
      return "discounts";

    case "OrderNumber":
      return "orders";

    case "PurchaseOrderId":
      return "inventory/purchasing_orders";

    case "PurchaseReturnId":
      return "inventory/purchase_returns";

    case "TransferNumber":
      return "inventory/transfers";

    case "AdjustNumber":
      return "inventory/stock_takes";

    case "SupplierId":
      return "inventory/suppliers";

    case "ProductId":
      return "products";

    case "VariantId":
      return "products";

    case "CustomerId":
      return "customers";

    case "OrderCreatedUser":
      return "settings/accounts";

    case "FulfillCreatedUser":
      return "settings/accounts";

    case "StaffMember":
    case "PackageCreatedUser":
      return "settings/accounts";

    case "PaidUserId":
      return "settings/accounts";

    case "ConfirmedUserId":
      return "settings/accounts";
    case "ShipmentCode":
      return "shipments";

    case "FromLocId":
    case "ToLocId":
    case "LocId":
    case "LocationId":
    case "LocationBranch":
    case "OrderLocationId":
    case "OrderAssignedLocationId":
    case "LocationTransaction":
    case "SaleLocId":
      return "settings/locations";

    case "SalesChannelBranchId":
      return "sale_channels";

    case "SalesChannelBranchName":
      return "sale_channels";

    case "InvReceiveId":
    case "InvId":
      return "inventory";
    case "RefId":
      return "";

    default:
      return "--";
  }
};

export const queryString: any = new Proxy(
  new URLSearchParams(window.location.search),
  {
    get: (searchParams, prop: any) => searchParams.get(prop),
  },
);

export function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export const renewInfo = (info, canReadMACost) => {
  const groupBy = info.lstReportScreenGroupProperty;
  let measure = info.lstReportScreenMeasure;
  // const filter = info.lstReportScreenFilter;

  let filterGroupBy = groupBy
    .filter((y) => y.isSelected === true)
    .sort((a, b) => {
      return a.selectOrderNumber - b.selectOrderNumber;
    })
    .map((x) => x.groupPropertyName.toLowerCase());

  if (!canReadMACost) {
    measure = info.lstReportScreenMeasure.filter(
      (y) =>
        !["MACostAmount", "Profit", "ProfitMargin"].includes(y.measureName),
    );
  }
  let filterMeasure = measure
    .filter((y) => y.isSelected)
    .map((x) => {
      return {
        measureField: x.measureField,
        measureName: x.measureName,
      };
    });

  // let filterFilter = BuildFilter(filter);

  return {
    groupby: filterGroupBy,
    metrics: filterMeasure,
    filters: [],
    order_by: {
      dimension: info.fieldsort,
      direction: info.sortype,
    },
    pagination: {
      offset: info.offset,
      limit: 100,
    },
  };
};

// ! reOrder xếp chỗ
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const slugify = (str: string): string => {
  // Chuyển hết sang chữ thường
  str = str.toLowerCase();

  // xóa dấu
  str = str
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

  // Thay ký tự đĐ
  str = str.replace(/[đĐ]/g, "d");

  // Xóa ký tự đặc biệt
  str = str.replace(/([^0-9a-z-\s])/g, "");

  // Xóa khoảng trắng thay bằng ký tự -
  str = str.replace(/(\s+)/g, "-");

  // Xóa ký tự - liên tiếp
  str = str.replace(/-+/g, "-");

  // xóa phần dư - ở đầu & cuối
  str = str.replace(/^-+|-+$/g, "");

  // return
  return str;
};

/**
 * Nhận vào danh sách filter sau đó chia filter thành từng group
 */
export const separateItemToGroup = (arrayFilter: any) => {
  // ! Chỉ lấy filter nào đang cho phép hiển thị
  const visibleFilter = arrayFilter.filter((y) => y.isDisplay);

  const result: any = {};

  for (let i = 0; i < visibleFilter?.length; i++) {
    const item = visibleFilter[i];
    if (!result[item.filterArea]) {
      result[item.filterArea] = [];
    }
    result[item.filterArea].push(item);
  }

  return result;
};

export const urlencode = (str: any) => {
  str = (str + "").toString();

  // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
  // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
  return encodeURIComponent(str)
    .replace(/!/g, "%21")
    .replace(/'/g, "%27")
    .replace(/\(/g, "%28")
    .replace(/\)/g, "%29")
    .replace(/\*/g, "%2A")
    .replace(/%20/g, "+");
};

export const buildQueryString = (
  queries: any,
  whiteList: any = [],
  bridgeSign: string = "?",
) => {
  const queryString: any = [];
  const checkWhitelist: boolean = whiteList.length > 0 ? true : false;

  Object.keys(queries).forEach((prop) => {
    if (queries[prop] !== "" && queries[prop].toString().length) {
      if (checkWhitelist) {
        if (whiteList.includes(prop)) {
          queryString.push(urlencode(prop) + "=" + urlencode(queries[prop]));
        }
      } else {
        queryString.push(urlencode(prop) + "=" + urlencode(queries[prop]));
      }
    }
  });

  return queryString.length > 0 ? `${bridgeSign}${queryString.join("&")}` : "";
};

/**
 * kiểm tra field input thuộc loại nào ? Render cho đúng UI
 */

export const xmlInputWithFieldName = (fieldName) => {
  const TYPE_INPUT = {
    inputMultipleSearchLocal: [
      "PaymentStatusId",
      "OrderStatusId",
      "BillingProvince",
      "ShippingProvince",
      "ShippingDistrict",
      "SourceName",
      "LocationId",
      "UserId",
      "OrderCreatedUser",
      "CarrierStatus",
      "CarrierCODStatus",
      "Status",
      "ParentStatus",
      "LotStatus",
      "CarrierId",
      "saleitemtype",
      "BillingProvinceName",
      "ShippingProvinceName",
      "ShippingDistrict",
      "Gateway",
      "ReasonId",
      "ismobile",
      "salekind",
      "LocId",
      "FromLocId",
      "ToLocId",
      "FromProvinceId",
      "ToProvinceId",
      "SalesChannelBranchName",
      "SalesChannelBranchId",
      "DiscountMethod",
      "Properties",
      "OrderAssignedLocationId",
      "InvReceiveId",
      "SaleLocId",
      "CustomerType",
      "CustomerProvinceId",
      "CustomerDistrictId",
      'ConfirmedUserId',
      'DiscountType',
      "CustomerSegment",
      "SocialChannel",
      "FulfillCreatedUser",
      "PackageCreatedUser",
      "PackageStatus",
      "TrackingNumbers",
      "PackageName",
      "SupplierId"
    ], //! Chỉ load auto search ở local
    inputMultipleSearchDB: [
      "OrderId",
      "CustomerId",
      "CustomerEmail",
      "ProductId", // ! sản phẩm lưu ý query
      "ProductVariantsId",
      "VariantId",
      "BillingCustomerName",
      "ProductName",
      "LotId",
      "Shipments",
      "ProductTypeName",
      "ProductVendorName",
      "DiscountId"
    ], //! load auto search ở DB
    inputField: [
      "HaravanAttributes.Value",
      "TrafficSource",
      "ReferringSite",
      "LandingSiteRef",
      "SKU",
      "UTMSource",
      "UTMMedium",
      "UTMCampaign",
      "UTMTerm",
      "UTMContent",
      "OrderDate",
      "BillingEmail",
      "BillingCompany",
      "CreatedDate",
      "LandingSite",
      "CustomerPhone",
      "SocialPage"
    ],
    numberField: [
      "Qty_OnHand", // number
      "Qty_Commited", // number
      "Qty_Incoming", // number
      "Qty_SoldPerDay",
      "Qty_Availability",
      "Qty_DayInventoryRemaining",
      "Qty_Value",
    ],
    date: ["CreatedDate"],
    // inputSingleSearchLocal: ["ProductCategoryId"], // ! Tạm thời close chọn single cho ngành hàng chuyển sang treelist
    inputSingleSearchDB: ["CollectionIds"],
    treeListWithSingle: ["ProductCategoryId"],
  };

  for (const key in TYPE_INPUT) {
    if (TYPE_INPUT[key].includes(fieldName)) {
      return key;
    }
  }
};

// ! Remove duplicate item undefined and null item
export function uniq(a) {
  let newArr = a.filter(
    (item, index) =>
      a.indexOf(item) === index && item !== null && item !== undefined,
  );

  return newArr;
}

// ! make number to string currency
export function formatCurrency(value, isRounded = false, roundNumber = 1) {
  const currencySymbols = [
    { value: 1e12, symbol: "k tỷ" },
    { value: 1e9, symbol: " tỷ" },
    { value: 1e6, symbol: "tr" },
    { value: 1e3, symbol: "k" },
  ];

  let isNegative = value < 0;
  value = Math.abs(value);

  for (let i = 0; i < currencySymbols.length; i++) {
    if (value >= currencySymbols[i].value) {
      let formattedValue: any = value / currencySymbols[i].value

      if (isRounded) {
        formattedValue = formattedValue.toFixed(roundNumber);
      }

      return (
        (isNegative ? "-" : "") + formattedValue + currencySymbols[i].symbol
      );
    }
  }


  let formattedValue = value.toFixed(0);
  return (isNegative ? "- " : "") + formattedValue;
}

export function ShowUIDate(datestart, dateend, isStockLot = false, isRFM = false) {
  const t = useTrans();
  let result = "";

  let ranges: any = {
    ...DATE_RANGES_DEFAULT(t),
    [t("nextSevenDays")]: [
      dayjs(),
      dayjs().add(6, 'day')],
    [t("nextMonth")]: [
      dayjs().add(1, 'month').startOf("month"),
      dayjs().add(1, 'month').endOf("month")
    ],
    [t("nextThreeMonths")]: [
      dayjs().add(1, 'month').startOf("month"),
      dayjs().add(3, 'month').endOf("month"),
    ],
    [t("nextSixMonths")]: [
      dayjs().add(1, 'month').startOf("month"),
      dayjs().add(6, 'month').endOf("month"),
    ],
  }

  if (isStockLot) {
    ranges = DATE_RANGES_STOCK_LOTS(t)
  }

  if (isRFM) {
    ranges = DATE_RANGES_RFM(t)
  }

  const formatTime = (time) => time.format("DD/MM/YYYY");
  let isInRange = false;

  for (const key in ranges) {

    if (
      formatTime(ranges[key][0]) === (formatTime(datestart)) &&
      formatTime(ranges[key][1]) === (formatTime(dateend))
    ) {
      isInRange = true;
      result = key;
    } else {
      if (!isInRange) {
        if (formatTime(datestart) === formatTime(dateend)) {
          result = formatTime(datestart);
        } else {
          const isTheSameYear = dayjs(datestart).year() === dayjs(dateend).year();

          result = `${!isTheSameYear
            ? `${formatTime(datestart)} - ${formatTime(dateend)}`
            : `${dayjs(datestart).format("DD/MM")} - ${dayjs(
              dateend,
            ).format("DD/MM/YYYY")}`
            }`;
        }
      }
    }
  }

  return result;
}

export function ShowDateRange(datestart, dateend) {
  const formatTime = (time) => dayjs(time).format("DD/MM/YYYY");
  if (formatTime(datestart) === formatTime(dateend))
    return formatTime(datestart);
  return `${formatTime(datestart)} - ${formatTime(dateend)}`;
}

export function ShowUIDatePeriod(datestart, dateend) {
  const diffDays = dateend.diff(datestart, "days");

  const formatTime = (time) => dayjs(time).format("DD/MM/YYYY");

  if (diffDays === 0) {
    if (formatTime(dateend) === formatTime(dayjs())) {
      return formatTime(dayjs().subtract(1, "days"));
    } else {
      return formatTime(dayjs().subtract(2, "days"));
    }
  } else {
    return `${formatTime(
      dayjs(datestart).subtract(diffDays + 1, "days"),
    )} - ${formatTime(dayjs(dateend).subtract(diffDays + 1, "days"))}`;
  }
}

export function convertStartDate(datestart) {
  const targetHour = 24;

  const newDate = dayjs(datestart)
    .subtract(1, "days")
    .hour(targetHour)
    .minute(0)
    .second(0)
    .toISOString();

  return newDate;
}

export function convertEndDate(datestart) {
  const targetHour = 24;

  const newDate = dayjs(datestart)
    .hour(targetHour)
    .minute(0)
    .second(0)
    .toISOString();

  return newDate;
}

export function comparePeriod(values) {
  const currentPeriod = values[0].data;

  const previousPeriod = values[1].data;

  const result: any = [];

  if (currentPeriod.length === 0) return [];

  currentPeriod.forEach((curr) => {
    previousPeriod.forEach((prev, pid) => {
      if (curr[0] === prev[0]) {
        let value = {
          name: curr[0],
          money: curr[1],
          trend: curr[1] - prev[1] < 0 ? false : true,
          valueTrend: (Math.abs(curr[1] - prev[1]) / prev[1]) * 100,
        };

        result.push(value);
      } else if (curr[0] !== prev[0] && pid === previousPeriod.length - 1) {
        let index = result.findIndex((y) => y.name === curr[0]);
        if (index === -1) {
          let value = {
            name: curr[0],
            money: curr[1],
            trend: 0,
            valueTrend: 0,
          };
          result.push(value);
        }
      }
    });
  });

  return result;
}

export function GetComparison(data, compare) {
  let value = 0;
  let infinity = true;
  let reverse = false;

  if (Number(compare) !== 0) {
    value = (Math.abs(data - compare) / compare) * 100;
    infinity = false;
  }

  return {
    trend: data - compare >= 0,
    value: Number(value.toFixed(2)),
    reverse,
    infinity,
  };
}

export function sprintf(str: String, ...args) {
  return str.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
}

export const comparisonDate = (date1, date2) => {
  const tstamp1 = dayjs(date1).format("x");
  const tstamp2 = dayjs(date2).format("x");

  if (tstamp1 >= tstamp2) {
    return true;
  } else {
    return false;
  }
};

export function ShowUIDateCompare(datestart, dateend, ranges) {
  if (typeof ranges === 'undefined') return;
  const t = useTrans();
  let result = "";
  if (datestart === undefined && dateend === undefined) return t("noCompare");

  const formatTime = (time) => dayjs(time).format("DD/MM/YYYY");

  let isInRange = false;
  for (const key in ranges) {
    if (
      formatTime(ranges[key][0]) === (formatTime(datestart)) &&
      formatTime(ranges[key][1]) === (formatTime(dateend))
    ) {
      isInRange = true;
      result = key;
    } else {
      if (!isInRange) {
        if (formatTime(datestart) === formatTime(dateend)) {
          result = formatTime(datestart);
        } else {
          const isTheSameYear = dayjs(datestart).year() === dayjs(dateend).year();

          result = `${!isTheSameYear
            ? `${formatTime(datestart)} - ${formatTime(dateend)}`
            : `${dayjs(datestart).format("DD/MM")} - ${dayjs(
              dateend,
            ).format("DD/MM/YYYY")}`
            }`;
        }
      }
    }
  }

  return result;
}

/**
 *
 * @param list: danh sách filter list
 * @return Array: danh sách string các dimension ẩn không cho hiển thị
 */
// & get tag not display in UI
export const getListDisplayFilter = (list) => {
  let dimensionHidden: any = list
    .filter((item) => !item.isDisplay)
    .map((y) => y.fieldName);

  return dimensionHidden;
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// ! Tổ chức cấu hình tên reports thành id
export function aliasReports(id) {
  const referenceName = {
    sales_orders_product: 100738,
  };

  let result = referenceName[id] || id;

  return result;
}

export const getPermissionColumn = (
  type: "measure" | "groupBy",
  role: "hide" | "delete" | "drag" | "add",
  reportName,
) => {
  let accept = true;

  if (DISABLE_DISPLAY_COLUMN_PERMISSIONS[type][role].includes(reportName)) {
    return false;
  }

  return accept;
};

// GroupArea sẽ disabled
export const getDisabledGroup = (reportName) => {
  switch (reportName) {
    case "order_shipments_detail_carriers_internal_speed":
      return ["Time"];

    case "order_shipments_overview_carriers_internal_speed":
      return ["Time"];

    default:
      return [];
  }
};

// GroupItem sẽ disabled
export const getDisabledItems = (reportName) => {
  switch (reportName) {
    case "order_shipments_detail_carriers_internal_speed":
      return ["Day", 'Month', 'DayOfWeek'];

    case "order_shipments_overview_carriers_internal_speed":
      return ["Day", 'Month', 'DayOfWeek'];

    case "stock_lots":
      return [
        "ProductId",
        "VariantId",
        "LotNo",
        "Day",
        "ExpireDaysRest",
      ];

    case "stock_remaining_days":
      return ["VariantId"];

    default:
      return [];
  }
};

export const requiredSelectAtLeastOneItemInGroup = (reportName) => {
  switch (reportName) {
    case "sales_overview_promotion":
      return ["Promotion"];

    default:
      return [];
  }
}

export const xmlRenderCheckbox = {
  order_shipments_detail_carriers_internal_speed: [
    "OrderToConfirmedTimeSpan",
    "ConfirmedToAssignedLocationTimeSpan",
    "ConfirmedToShipmentTimeSpan",
    "AssignedLocationToInstockConfirmedTimeSpan",
    "InstockConfirmedToExportTimeSpan",
    "ExportToShipmentTimeSpan",
    "ShipmentToHandOverTimeSpan",
  ],
  order_shipments_overview_carriers_internal_speed: [
    "OrderToShipmentTimeSpan",
    "ShipmentToHandOverTimeSpan",
  ],
};

// ! Sort 1 array theo 1 array khác
export const mapOrder = (originalArray, orderArray, key) => {
  if (!originalArray || !orderArray || !key) return [];

  const clonedArray = [...originalArray];
  const orderedArray = clonedArray.sort((a, b) => {
    return orderArray.indexOf(a[key]) - orderArray.indexOf(b[key]);
  });

  return orderedArray;
};

// ! numberal JS decimal to 3
export const FormatDecimalQuantity = (number) => {
  return Numeral(Numeral(number).format("0,0.[000]")).value();
};

export const FormatMoneyLocale = (value) => {
  let formater = GetCurrencyFormater();
  let options = formater.resolvedOptions();
  let formatedValue = formater.format(Math.round(value));
  if (options.locale === "vi-VN") {
    formatedValue = PointsToCommas(formatedValue);
  }

  return formatedValue;
};

export const isOpenForFeaturesPackage = (currentOpen, featuresList) => {
  let flag = false;

  if (!currentOpen.length) {
    // ! TH: mở all cho tất cả các shop
    flag = true;
  } else {
    // ! TH: mở cho 1 số shop
    currentOpen.forEach((value) => {
      const isHasPackage =
        featuresList?.filter((p: any) => p.product_code.includes(value))
          .length > 0;

      if (isHasPackage) {
        flag = true;
        return;
      } else {
        flag = false;
      }
    });
  }

  return flag;
};

export const groupArrayByField = (array, field) => {
  return array.reduce((acc, obj) => {
    const key = obj[field];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Clone the object before pushing it into the array
    acc[key].push({ ...obj });
    return acc;
  }, {});
};

export const getPositionWidget = (template) => {
  let maxX,
    maxY = 0;

  let layouts: any = [];

  for (const key in template) {
    if (Object.prototype.hasOwnProperty.call(template, key)) {
      const element = template[key];
      const getReportDisplay = element.filter((t) => t.isDisplay);

      if (getReportDisplay.length) {
        layouts.push([...getReportDisplay]);
      }
    }
  }

  layouts = layouts.flat().map((y: any) => {
    return {
      ...y.template,
      id: y.id,
    };
  });

  let filterY: any = layouts.map((item) => item.y);

  maxY = Math.max(...filterY);

  return { maxX, maxY };
};

export function deepClone(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    const arrCopy: any = [];
    for (let i = 0; i < obj.length; i++) {
      arrCopy[i] = deepClone(obj[i]);
    }
    return arrCopy;
  }

  const objCopy = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      objCopy[key] = deepClone(obj[key]);
    }
  }
  return objCopy;
}

export const handlerLabelInDatePicker = (label) => {
  if (label === "Today") {
    return "Hôm nay";
  }

  if (label === "Yesterday") {
    return "Hôm qua";
  }

  if (label === "7 days ago") {
    return "7 ngày trước";
  }

  if (label === "30 days ago") {
    return "30 ngày trước";
  }

  if (label === "This month") {
    return "Tháng này";
  }

  if (label === "Last month") {
    return "Tháng trước";
  }

  if (label === "Custom") {
    return "Tùy chọn";
  }

  if (label === "Previous Period") {
    return "Kỳ trước";
  }

  if (label === "Previous Year") {
    return "Năm trước";
  }

  if (label === "No Compare") {
    return "Không so sánh";
  }

  return label;
};

/**
 * Function: Create color with string
 * Input with String and this Function will return a color with hashing a string to HSL color
 */
export const generatingColorForString = (str) => {
  const getHashOfString = (str) => {
    const charArray = Array.from(str);
    return charArray.reduce((total: any, _char, index) => {
      return (total += str.charCodeAt(index) * index);
    }, 0);
  };

  const normalizeHash = (hash: number, min: number, max: number) => {
    return Math.floor((hash % (max - min)) + min);
  };

  const hRange = [0, 360];
  const sRange = [60, 80];
  const lRange = [40, 60];

  const generateHSL = (name: string) => {
    const hash: any = getHashOfString(name);
    const h = normalizeHash(hash, hRange[0], hRange[1]);
    const s = normalizeHash(hash, sRange[0], sRange[1]);
    const l = normalizeHash(hash, lRange[0], lRange[1]);
    return [h, s, l];
  };

  const HSLtoString = (hsl) => {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
  };

  return HSLtoString(generateHSL(str));
};

const ILocaleType = {
  'VN': 'vi',
  'EN': 'en-au'
};

const ConfigDateTime_VI = function (locale) {
  dayjs.updateLocale(locale, {
    months: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
    weekdays: ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy'],
    longDateFormat: {
      LT: "hh:mm A",
      LTS: "hh:mm:ss A",
      L: "DD/MM/YYYY",
      LL: "Do MMMM YYYY",
      LLL: "Do MMMM YYYY LT",
      LLLL: "dddd, Do MMMM YYYY LT"
    },
    calendar: {
      sameDay: "[Hôm nay] LT",
      nextDay: "[Ngày mai] LT",
      nextWeek: "L LT",
      lastDay: "[Hôm qua] LT",
      lastWeek: "L LT",
      sameElse: "L LT"
    },
    relativeTime: {
      future: "%s trước",
      past: "%s trước",
      s: 'vài giây',
      ss: '%d giây',
      m: "1 phút",
      mm: "%d phút",
      h: "1 giờ",
      hh: "%d giờ",
      d: "1 ngày",
      dd: "%d ngày",
      M: "1 tháng",
      MM: "%d tháng",
      y: "1 năm",
      yy: "%d năm"
    },
    meridiem: function (hour, minute, isLowercase) {
      return hour < 12 ? 'SA' : 'CH';
    },
    invalidDate: "Ngày không hợp lệ"
  });
};

const ConfigDateTime_EN = function (locale) {
  dayjs.updateLocale(locale, {
    calendar: {
      sameDay: "[Today] LT",
      nextDay: "[Tomorrow] LT",
      nextWeek: "L LT",
      lastDay: "[Yesterday] LT",
      lastWeek: "L LT",
      sameElse: "L LT"
    },
    meridiem: function (hour, minute, isLowercase) {
      return hour < 12 ? 'AM' : 'PM';
    },
    invalidDate: "Invalid Date"
  });
};

const baseSettingMoment = function () {
  var locale = dayjs.locale();

  switch (locale) {
    case ILocaleType.VN:
      ConfigDateTime_VI(locale);
      break;
    case ILocaleType.EN:
      ConfigDateTime_EN(locale);
      break;
    default:
      ConfigDateTime_VI(locale);
      break;
  }
};

const MomentLocaleCalendar = function (date, format) {
  if (date === void 0) { date = new Date(); }
  var nowTime = new Date();

  baseSettingMoment();
  if (format && format !== '') {
    return dayjs(new Date(date)).format(format);
  }

  var inOneHour = dayjs(nowTime).diff(dayjs(date), 'minutes');

  if (inOneHour >= 0 && inOneHour < 60) {
    return dayjs(date).fromNow();
  }

  return dayjs(date).calendar();
};

export const FormatDateTimeCustom = (date, format) => {
  if (format === void 0) { format = "DD/MM/YYYY"; }
  return MomentLocaleCalendar(date, format);
}

export const implementLongStringInChart = (arrayLabels, numberGroup = 2) => {
  let groupedArray: any = [];

  for (let i = 0; i < arrayLabels?.length; i += numberGroup) {
    let pair = arrayLabels?.slice(i, i + numberGroup).join(' ');
    groupedArray.push(pair);
  }


  return groupedArray
}


// ! Download by URL and edit name file
export async function downloadTriggered(url, filename) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Error with open file action');
    }
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.href = blobUrl;

    // Set the download attribute with the desired filename
    downloadLink.download = filename || 'download';

    // Append the link to the body
    document.body.appendChild(downloadLink);

    // Trigger a click on the link to initiate the download
    downloadLink.click();

    // Remove the link from the DOM
    document.body.removeChild(downloadLink);

    window.URL.revokeObjectURL(blobUrl);
  } catch (err) {
    console.error("Error in fetching and downloading file:", err);
  }
}

export const renameFile = (oldName: string) => {
  return slugify(oldName)
}

/**
 * Remove Function with 2 Array 
 * Array1 (simple): ['a', 'b', 'c']
 * Array2 (Complex): [{key: 'a'}, {key: 'd'}]
 * Result : Return item not include in Array1
 */

export const removeFlatArray = (arr1, arr2, key) => {
  const result = arr1.length ? arr2.filter(item2 => !arr1.includes(item2[key])) : arr2;


  return result;
}

/**
 * @Function Thay thế chuỗi với tham số
 * @param inputString "Tôi hôm nay cần {MONEY}VND"
 * @param args { MONEY : 3000 }
 * @returns => Tôi hôm nay cần 3000VND
 */

export const replaceParams = (inputString, args = {}) => {
  if (inputString && inputString.length) {
    let final = '';
    for (const key in args) {
      if (Object.prototype.hasOwnProperty.call(args, key)) {
        const myValue = args[key];

        final = inputString.replaceAll(`{${key}}`, myValue)

      }
    }

    return final;
  } else {
    return "NOT_TRANSLATED"
  }
};

export const setPrintDescription = ({
  selectFilter,
  filters,
  template,
  reportNameSystem
}: {
  selectFilter: any,
  filters: any[],
  template: any,
  reportNameSystem: string
}) => {
  const result: any = {};
  const getSupportFields = template[reportNameSystem] || template['default'];

  getSupportFields.forEach((dimension: string) => {
    const filterName = filters.find(item => item.fieldName === dimension)?.filterName || dimension;
    const hiddenProductByCategoryId = selectFilter.map((item) => {
      return {
        ...item,
        dimension: item.dimension
      }
    });

    const findIndexTag = hiddenProductByCategoryId.findIndex(item => item.dimension === dimension);
    const valueDescription = findIndexTag !== -1 ? selectFilter[findIndexTag]?.query?.map(y => y.query).join(", ") : "";

    result[filterName] = valueDescription;
  });

  return result;
};

/**
 * Hiển thị position của text bên trong bảng theo format data
 * format thuộc NUMBERIC_TYPE => bên phải || còn lại bên trái
 */

const NUMBERIC_TYPE = [
  "money",
  "number",
  "percent",
  "numberlink",
  "numberday",
  "numberhour",
];

export const alignmentText = (format) => {
  return NUMBERIC_TYPE.includes(format)
    ? "hrv-report-text-right"
    : "hrv-report-text-left";
};

export const REPORT_SUMMARY_PREVENT = [
  "stock_lots",
  "cas_transaction_pnl",
  "stock_remaining_days",
  "sales_overview_promotion",
  "order_shipments_overview_carriers_internal_speed",
  "order_shipments_detail_carriers_internal_speed",
  "shipments_carriers_status_by_time",
  "customer_new_old"
];

// & Gọi API summary cho ("stock_transactionhistory_adv", "stock_transactionhistory_alltime")
export const isCallSummaryApi = ({ reportNameSystem, betaFeatures, metrics, filtersDefault, payload }) => {
  let isDisplay = !REPORT_SUMMARY_PREVENT.includes(reportNameSystem);

  if (isDisplay) {
    isDisplay = !filtersDefault.some(element => metrics.some(y => y.measureName === element.dimension));
  }

  if (reportNameSystem === "payments_deb_supplier") {
    // ! Nếu báo cáo là công nợ nhà cung cấp khi vào drilldown ko hiển thị summary
    if (payload?.groupby?.findIndex(y => y === "supplierid") === -1) {
      isDisplay = false;
    }
  }

  if (reportNameSystem === "payments_deb_customer") {
    // ! Nếu báo cáo là công nợ khách hàng khi vào drilldown ko hiển thị summary
    if (payload?.groupby?.findIndex(y => y === "customerid") === -1) {
      isDisplay = false;
    }
  }

  return isDisplay;
}

export const valueSheet = (index, headers) => {
  const { dataFormat, fieldName } = headers[index];

  switch (dataFormat) {
    case 'number':
    case "numberlink":
    case "money":
      if (fieldName.includes("Qty_")) {
        // * Format Decimal
        return {
          t: 'n',
          z: "#,##0.000"
        }
      } else {
        return {
          t: 'n',
          z: "#,##0"
        }
      }

    case 'percent':
      return {
        t: 'n',
        z: "0.00%"
      }

    default:

      return {
        t: 's',
        z: fieldName === "CustomerId" ? "guest" : ""
      }
  }
}

export const buildXLSXFromTable = (table, name, headers, t) => {
  // Convert HTML table to workbook
  const wb = XLSX.utils.table_to_book(table, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
  // Get the first sheet from the workbook
  const ws: any = wb.Sheets[wb.SheetNames[0]];

  const maxWidths: any = []; // configure width with string of length

  // Iterate over each cell in the worksheet
  for (let cellAddress in ws) {
    if (!ws.hasOwnProperty(cellAddress) || cellAddress[0] === '!') continue; // Skip non-cell elements
    const colIndex = XLSX.utils.decode_col(cellAddress.replace(/\d/g, '')); // Extract column index
    const rowIndex = XLSX.utils.decode_row(cellAddress.replace(/\D/g, ''));
    const cellContent = ws[cellAddress].v.toString(); // Get cell content
    let cellWidth = cellContent.length; // Calculate cell width

    if (rowIndex === 0) {
      // & Hàng mang giá trị index = 0 được định nghĩa là header và sẽ format auto theo String
      ws[cellAddress].v = t(cellContent);
      cellWidth = t(cellContent).length;
      ws[cellAddress].t = 's'
      ws[cellAddress].s = { font: { bold: true, color: { rgb: "FF0000" } } }

    } else {
      // & Hàng mang giá trị index = 0 được định nghĩa là header và sẽ format auto theo String
      ws[cellAddress].t = valueSheet(colIndex, headers).t;
      ws[cellAddress].z = valueSheet(colIndex, headers).z;

      if (valueSheet(colIndex, headers).z === "guest") {
        ws[cellAddress].v = t(cellContent);
      }
    }

    // Update maximum width for current column
    if (!maxWidths[colIndex] || cellWidth > maxWidths[colIndex]) {
      maxWidths[colIndex] = cellWidth;
    }
  }

  maxWidths.forEach((width, colIndex) => {
    ws['!cols'][colIndex] = { wch: width + 2 }; // Add some extra width for padding
  });

  XLSX.writeFile(wb, `${name}.xlsx`);
}

/**
 * Merge 2 mảng lại với nhau nếu có phần tử trùng sẽ lấy phần tử trong mảng 2
 * @param arr1: mảng 1
 * @param arr2 : mảng 2 
 * @param propertyName: Thuộc tính
 * @returns mảng gồm phần tử trong mảng 1 và mảng 2
 */
export function mergeArraysByPropertyName(arr1, arr2, propertyName, reportName) {
  let map = new Map();

  // ! Tìm dimension đang bị ẩn để giữ lại => trả về kết quả filter mới
  const hiddenFilterDimension = {
    "shipments_carriers_status_by_time": ["CarrierId"],
    "shipments_carriers_status_by_location": ["CarrierId"],
    "order_shipments_detail_carriers_internal_speed": ["FromLocId"],
    "order_shipments_overview_carriers_internal_speed": ["FromLocId"],
    "stock_remaining_days": ["LocId"],
    "sales_orders_salechannel_branch": ["SalesChannelBranchName"],
    "stock_invreceive_by_suppliers": ['SupplierId'],
    "sales_promotion": ['DiscountId'],
    "sales_overview_promotion": ['DiscountId'],
    // "payments_deb_supplier": ['SupplierId'],
  };

  const hiddenFilter: any = [];

  arr1.forEach(item1 => {
    if (hiddenFilterDimension[reportName] && hiddenFilterDimension[reportName]?.length && hiddenFilterDimension[reportName].includes(item1[propertyName])) {
      hiddenFilter.push(item1)
    }
  })

  // * Assign lại filter mới
  arr2.forEach(item => {
    if (item[propertyName]) {
      map.set(item[propertyName], item);
    }
  });

  // * Set lại kết quả dimension bị ẩn: filter mới + dimension bị ẩn
  hiddenFilter.forEach(item => {
    if (item[propertyName]) {
      map.set(item[propertyName], item);
    }
  });

  let result = Array.from(map.values());

  return result;
}

/**
 * Kiểm tra GroupBy và Measure có bị bỏ ra khỏi bảng hay không ?
 * Ví dụ: cột đang sort theo ngày nhưng lại bỏ cột ngày ra khỏi GroupBy (hoặc đối với Measure)
 * Giải pháp: Lấy GroupBy đầu tiên làm chuẩn để sort
 * @params groupBy: groupBy mới
 * @params measure: measure mới
 * @params sort: tăng hay giảm
 */
export const compareOrderBy = (
  { groupBy,
    measure,
    sort,
    lastestOrderBy
  }
) => {
  const sortName = sort.dimension; // Tên cột đang sort

  let oldOrderBy = lastestOrderBy

  // Tìm order by theo groupBy có bị bỏ ra 
  const isSortValueExistInGroupBy = groupBy.length
    ? groupBy.findIndex(
      (y) => y.groupPropertyName === sortName,
    ) !== -1
    : false;

  // Tìm order by theo measure có bị bỏ ra 
  const isSortValueExistInMeasure = measure.length
    ? measure.findIndex((y) => y.measureName === sortName) !== -1
    : false;

  // Nếu cả 2 groupBy và measure đều bị bỏ ra => tạo 1 order by mới
  if (!isSortValueExistInGroupBy && !isSortValueExistInMeasure) {
    let newOrderByPayload = {
      dimension: groupBy[0].groupPropertyName, // Sort theo groupBy đầu tiên
      direction: oldOrderBy.direction,// Tăng hay giảm lấy lại giá trị cũ
    };

    oldOrderBy = newOrderByPayload;
  }

  return oldOrderBy;
};

export const limitString = (text: string, startOf = 0, endOf = 40, prefix = "...") => {
  return text.length > endOf ? text.slice(startOf, endOf) + prefix : text
}

/**
 * 
 * @param startdate ngày bắt đầu
 * @param enddate ngày kết thúc
 * @param diffCounts khoảng cách số ngày từ ngày bắt đầu đến ngày kết thúc
 * @Function Kiểm tra ngày bắt đầu và ngày kết thúc có phải là ngày đầu tiên và ngày cuối cùng trong tháng
 * @returns 
 */

export const getAdjustedComparisonDates = (startdate: any, enddate: any, diffCounts: number, dateShowType: any) => {
  let startCompareDate = dayjs();
  let endCompareDate = dayjs();
  const startDateWithEndMonth = startdate.clone().startOf('month');
  const endDateWithEndMonth = enddate.clone().endOf('month');

  const isStartOfMonth = startDateWithEndMonth.isSame(startdate, 'day');
  const isEndOfMonth = endDateWithEndMonth.isSame(enddate, 'day');

  if (isStartOfMonth && isEndOfMonth) {
    switch (dateShowType) {
      case 'day':
        if (diffCounts <= 59) {
          startCompareDate = startdate.clone().subtract(1, 'months').startOf('month');
          endCompareDate = enddate.clone().subtract(1, 'months').endOf('month');
        } else {
          startCompareDate = startdate.clone().subtract(2, 'months').startOf('month');
          endCompareDate = startdate.clone().subtract(1, 'months').endOf('month');
        }
        break;

      case 'month':
        startCompareDate = startdate.clone().subtract(diffCounts, 'months').startOf('month');
        endCompareDate = startdate.clone().subtract(1, 'months').endOf('month');
        break;


      default:
        break;
    }
  } else {
    startCompareDate = dayjs(dayAdd(startdate, diffCounts * -1, dateShowType));
    endCompareDate = dayjs(dayAdd(enddate, diffCounts * -1, dateShowType));
  }


  return {
    start: startCompareDate,
    end: endCompareDate
  }
}

export function formatPercentage(value, total) {
  let percentage = (value / total) * 100;
  if (Number.isInteger(percentage)) {
    return percentage.toFixed(0) + '%';
  } else {
    return percentage.toFixed(1).replace('.0', '') + '%';
  }
}

export const encodeUrlParams = (path) => {
  const [pathname, queryString] = path.split("?");

  if (!queryString) {
    return pathname;
  }

  const encodedParams = queryString.split("&").map((param) => {
    const index = param.indexOf("=");
    const key = param.slice(0, index);
    const value = param.slice(index + 1);

    return `${key}=${encodeURIComponent(value)}`;
  });

  const encodedQueryString = encodedParams.join("&");

  return encodedQueryString ? `${pathname}?${encodedQueryString}` : pathname;
};

export const checkReportTabByPackages = (selected, planName) => {
  let packagesNotAllow: Array<string> = [];
  let plans: Array<string> = [];
  let defaultPlans: string = planName

  const PLAN_NAME: Record<string, string> = {
    OMNISTANDARD: "omnistandard",
    STANDARD: "standard",
    ADVANCED: "advanced",
    PRO: "pro",
    GROW: "grow",
    SCALE: 'scale',
  };

  const PACKAGES_ACCESS: Record<string, Array<string>> = {
    [PLAN_NAME.OMNISTANDARD]: ["sales", 'payments', 'web'],
    [PLAN_NAME.STANDARD]: ["sales", 'payments', 'web'],
    [PLAN_NAME.ADVANCED]: ["sales", 'payments', 'inventory', 'web'],
    [PLAN_NAME.PRO]: ["sales", 'payments', 'inventory', 'web'],
    [PLAN_NAME.GROW]: ["sales", "shipments", 'payments', 'inventory', 'web', 'customers'],
    [PLAN_NAME.SCALE]: ["sales", "shipments", 'payments', 'inventory', 'web', 'customers'],
  };

  const isHasPackage = PACKAGES_ACCESS[defaultPlans]

  if (!isHasPackage) {
    defaultPlans = PLAN_NAME.STANDARD
  };

  selected.forEach((item: string) => {
    if (!PACKAGES_ACCESS[defaultPlans].includes(item)) {
      packagesNotAllow.push(item);
    }

    const keys = Object.keys(PACKAGES_ACCESS);

    for (let i = 0; i < keys.length; i++) {
      const keyName = keys[i];

      if (PACKAGES_ACCESS[keyName].includes(item)) {
        plans.push(keyName);
        return;
      }
    }
  });

  return { packages: packagesNotAllow, plans }
};

export const valueFromCurrency = (value) => {
  let formater = GetCurrencyFormater();
  let options = formater.resolvedOptions();
  let formatedValue = formater.format(Math.round(value));
  if (options.locale === "vi-VN") {
    formatedValue = PointsToCommas(formatedValue);
  }

  return formatedValue
}

export const middleEllipsisHandler = (text) => {
  if (text.split(">").length > 2) {
    const getFirst = text.split(">")[0];
    const getLast = text.split(">")[text.split(">").length - 1];
    return getFirst + " > ... > " + getLast
  } else {
    return text
  }
};
