type Strategy = {
    handler(args: any): any;
}

enum DrillDownName {
    ProductCategoryId = "ProductCategoryId",
    SupplierId = "SupplierId",
    CustomerId = "CustomerId"
}

export enum DrilldownAction {
    OPEN = 'open',
    BACK = 'back'
}

enum DrilldownNameAction {
    ProductCategoryId_open = "ProductCategoryId_open",
    ProductCategoryId_back = "ProductCategoryId_back",
    SupplierId_open = "SupplierId_open",
    SupplierId_back = "SupplierId_back",
    CustomerId_open = "CustomerId_open",
    CustomerId_back = "CustomerId_back",
}

function addOrUpdateObjectInArray(array, object, field) {
    const conditions = {
        "ProductByCategoryId": item => item.dimension !== 'ProductCategoryId' && item.dimension !== field,
        "ProductCategoryId": item => item.dimension !== 'ProductByCategoryId' && item.dimension !== field,
        "SupplierId": item => item.dimension !== field
    };

    const removeOldData = array.filter(item => conditions[field] ? conditions[field](item) : true);

    return removeOldData.concat(object);
}

function removeDuplicatesByField(array, field) {
    const seen = new Set();
    return array.filter(item => {
        const value = item[field];
        if (seen.has(value)) {
            return false;
        }
        seen.add(value);
        return true;
    });
}

class DrilldownStategyPattern {
    strategies: Record<string, Strategy> = {};

    use(name: any, strategy: Strategy) {
        this.strategies[name] = strategy;
    }

    handler(name: DrillDownName, action: DrilldownAction, ...args: any) {
        if (!Object.values(DrillDownName)?.includes(name)) {
            console.error("Please input right Drilldown Stategy Name");

            return false;
        }

        if (Object.values(DrilldownAction)?.includes(action)) {
            return this.strategies[`${name}_${action}`].handler.apply(null, args);
        } else {

            console.error("Please input right Drilldown Stategy Action");
            return false;
        }

    }
}

class OpenProductCategoryIdStrategy implements Strategy {
    handler(args: any) {
        const { groupBySelected, isLastChild, groupBy, name, list, value, filterTag, t } = args;
        const findItem = list.data.find(item => item.id === value);

        let renewGroupBy = [...groupBySelected];
        const findItemRemoveIndex = renewGroupBy.findIndex(y => y.groupPropertyField === "ProductCategoryId");
        const findItemAddIndex = groupBy.findIndex(y => y.groupPropertyField === "ProductId");

        if (isLastChild) {
            // * Nếu là lastchild => change view groupby
            renewGroupBy.splice(findItemRemoveIndex, 1, groupBy[findItemAddIndex]);
        }

        const currentProductCategoryId = {
            conjunction: "and",
            dimension: "ProductCategoryId",
            // dimension: !isLastChild ? "ProductCategoryId" : "ProductByCategoryId",
            query: [{
                conjunction: 'or',
                symbol: "is",
                displayText: `${t(name)} ${t("is")} ${findItem.name}`,
                query: findItem.name,
                value: findItem.value,
            }]
        };

        const newFilter = addOrUpdateObjectInArray(filterTag, currentProductCategoryId, "ProductCategoryId");

        return { groupByNew: renewGroupBy, filters: newFilter, currentItem: findItem, rename: "ProductCategoryId" };
    }
}

class OpenSupplierIdStrategy implements Strategy {
    handler(args: any) {
        const {
            name,
            list,
            value,
            groupBy,
            measure,
            groupBySelected,
            t,
            filterTag,
            reportNameSystem
        } = args;

        let renewGroupBy: any = JSON.parse(JSON.stringify([...groupBySelected]));
        let renewMeasure: any = [];
        const findItem = list.data.find(item => item.id === value);
        console.log({ findItem })

        // ! Ẩn group by Nhà cung cấp
        let findItemRemove = renewGroupBy.findIndex(y => y.groupPropertyField === "SupplierId");

        const newGroups: any = [];
        const newMeasures: any = [];

        if (reportNameSystem === "stock_invreceive_by_suppliers") {
            // ! Hiện group by Sản phẩm, Biển thể (Lấy index)
            groupBy.forEach((y, index) => {
                if (["ProductId", "VariantId"].includes(y.groupPropertyField)) {
                    newGroups.push(index)
                }
            })
        } else if (reportNameSystem === "payments_deb_supplier") {
            // ! Hiện group by Sản phẩm, Biển thể (Lấy index)
            groupBy.forEach((y, index) => {
                if (["TranDate", "RefId", "TypeId"].includes(y.groupPropertyField)) {
                    newGroups.push(index)
                }
            })

            // ! Hiện measure Giá trị, Dư nợ cuối
            measure.forEach((y, index) => {
                if (["ClosingDebtAmount", "CostAmount"].includes(y.measureField)) {
                    newMeasures.push(index)
                }
            })
        }

        // ! Hiện group by Thời gian nhập hàng
        renewGroupBy.splice(findItemRemove, 1);

        newGroups.forEach((index) => {
            renewGroupBy.push(groupBy[index])
        });

        newMeasures.forEach((index) => {
            renewMeasure.push(measure[index])
        })

        let currentSupplierId: any = {
            conjunction: "and",
            dimension: name,
            query: []
        }

        currentSupplierId.query = [{
            conjunction: 'or',
            symbol: "is",
            displayText: `${t(name)} ${t("is")} ${findItem.name}`,
            query: findItem.name,
            value: findItem.value,
        }];

        const newFilter = addOrUpdateObjectInArray(filterTag, currentSupplierId, name);

        const newName = name;

        return {
            groupByNew: renewGroupBy,
            measureNew: renewMeasure,
            filters: newFilter,
            currentItem: findItem,
            rename: newName
        };
    }
}

class OpenCustomerIdStrategy implements Strategy {
    handler(args: any) {
        const {
            name,
            list,
            value,
            groupBy,
            measure,
            groupBySelected,
            t,
            filterTag,
            reportNameSystem
        } = args;

        let renewGroupBy: any = JSON.parse(JSON.stringify([...groupBySelected]));
        let renewMeasure: any = [];
        const findItemIndex = list.dataLink.findIndex(item => item[0] === value);

        // ! Ẩn group by Tên khách hàng
        let findItemRemove = renewGroupBy.findIndex(y => y.groupPropertyField === "CustomerId");

        const newGroups: any = [];
        const newMeasures: any = [];

        if (reportNameSystem === "payments_deb_customer") {
            // ! Hiện group by Sản phẩm, Biển thể (Lấy index)
            groupBy.forEach((y, index) => {
                if (["TranDate", "RefId", "TypeId"].includes(y.groupPropertyField)) {
                    newGroups.push(index)
                }
            })

            // ! Hiện measure Giá trị, Dư nợ cuối
            measure.forEach((y, index) => {
                if (["ClosingDebtAmount", "CostAmount"].includes(y.measureField)) {
                    newMeasures.push(index)
                }
            })
        }

        // ! Hiện group by Thời gian nhập hàng
        renewGroupBy.splice(findItemRemove, 1);

        newGroups.forEach((index) => {
            renewGroupBy.push(groupBy[index])
        });

        newMeasures.forEach((index) => {
            renewMeasure.push(measure[index])
        })

        let currentCustomerId: any = {
            conjunction: "and",
            dimension: name,
            query: []
        }

        let findItem = {
            name: list.data[findItemIndex][0],
            value: list.dataLink[findItemIndex][0],
        }

        currentCustomerId.query = [{
            conjunction: 'or',
            symbol: "is",
            displayText: `${t(name)} ${t("is")} ${findItem.name}`,
            query: findItem.name || "",
            value: findItem.value || "",
        }];

        const newFilter = addOrUpdateObjectInArray(filterTag, currentCustomerId, name);

        const newName = name;

        return {
            groupByNew: renewGroupBy,
            measureNew: renewMeasure,
            filters: newFilter,
            currentItem: findItem,
            rename: newName
        };
    }
}

class BackProductCategoryId implements Strategy {
    handler(args: any) {
        const {
            groupBySelected,
            groupBy,
            list,
            filterTag,
        } = args;

        let renewGroupBy: any = JSON.parse(JSON.stringify([...groupBySelected]));
        let renewFilterTag: any = JSON.parse(JSON.stringify([...filterTag]));
        let newQuery: any = [];

        // & [GroupBy] Delete all GroupBy Add add new field ProductCategoryId
        renewGroupBy = renewGroupBy.filter(y => y.groupPropertyField === "ProductCategoryId");

        let indexFilterProductCategoryId = renewFilterTag.findIndex(y => y.dimension === "ProductCategoryId");
        // let indexFilterProductCategoryId = renewFilterTag.findIndex(y => y.dimension === "ProductCategoryId" || y.dimension === 'ProductByCategoryId');

        const childId = renewFilterTag[indexFilterProductCategoryId].query[0].value;

        // & [Filter] Find parentId by childId
        let parentId = list.data.find(item => {
            return item.id === (childId)
        }).specialInfo.parentId;


        /**
         * Khi trở lại sẽ có 2 TH
         * TH1: Trường hợp từ SP thuộc + ngành hàng khác > Show sản phẩm => Khi back lại thì parentId sẽ là childrenId
         * (SP thuộc trực tiếp ngành hàng ABC) <Click> |
         * Ngành hàng 1                                |
         * Ngành hàng 2                                |
         *
         * TH2: Trường hợp từ ngành hàng (Không còn ngành hàng con) > Show sản phẩm => Khi back lại thì parentId sẽ là parentId
         * (SP thuộc trực tiếp ngành hàng ABC) |
         * Ngành hàng 1 <Click>                |
         * Ngành hàng 2                        |
         */

        // (Nếu back ra mà đang đứng ở view "ProductByCategoryId" và là Sản phẩm thuộc (isLastChild === true) thì gán lại parentId là childId)
        // if (name === "ProductByCategoryId" && isLastChild) {
        //     parentId = childId
        // }

        if (parentId !== 0) {
            // & [Filter]: If ProductByCategoryId when back haven't parentId (parentId === 0), we use childId for that.
            const queryId = parentId === 0 ? parseInt(childId) : parseInt(parentId);

            let parentQueryIndex = list.data.findIndex(item => parseInt(item.id) === queryId)

            const { displayname_vi, name: dimension, value } = list.data[parentQueryIndex];

            // & [Filter] Assign new Query to filter
            newQuery = [{
                conjunction: "or",
                displayText: displayname_vi,
                query: dimension,
                symbol: 'is',
                value
            }];
        };



        if (!Boolean(parseInt(childId)) && !Boolean(parseInt(parentId))) {
            // TH: tên ngành hàng là "--"
            renewFilterTag.splice(indexFilterProductCategoryId, 1)
        } else {
            if (newQuery.length) {
                renewFilterTag[indexFilterProductCategoryId].dimension = "ProductCategoryId"
                renewFilterTag[indexFilterProductCategoryId].query = newQuery;
            } else {
                renewFilterTag.splice(indexFilterProductCategoryId, 1)
            }
        }

        if (!renewGroupBy.length) {
            let findItemAdd = groupBy.findIndex(y => y.groupPropertyField === "ProductCategoryId")

            renewGroupBy.push(groupBy[findItemAdd])
        };

        return {
            parentId,
            groupByNew: renewGroupBy,
            filters: renewFilterTag,
            currentItem: newQuery,
            rename: "ProductCategoryId"
        };
    }
}

class BackSupplierId implements Strategy {
    handler(args: any) {
        const {
            groupBySelected,
            groupBy,
            filterTag,
            reportNameSystem,
            measure
        } = args;

        let renewGroupBy: any = JSON.parse(JSON.stringify([...groupBySelected]));
        let renewMeasure: any = [];
        let renewFilterTag: any = JSON.parse(JSON.stringify([...filterTag]));
        let newQuery: any = [];

        if (reportNameSystem === "stock_invreceive_by_suppliers") {
            // ! Ẩn group by Sản phẩm
            let findItemRemoveProductId = renewGroupBy.findIndex(y => y.groupPropertyField === "VariantId")

            // ! Ẩn group by Biến thể
            let findItemRemoveVariantId = renewGroupBy.findIndex(y => y.groupPropertyField === "ProductId")

            renewGroupBy.splice(findItemRemoveProductId, 1);
            renewGroupBy.splice(findItemRemoveVariantId, 1);
        } else if (reportNameSystem === "payments_deb_supplier") {
            const removeGroupBy = ["TranDate", "RefId", "TypeId"];

            for (let i = renewGroupBy.length - 1; i >= 0; i--) {
                if (removeGroupBy.includes(renewGroupBy[i].groupPropertyField)) {
                    renewGroupBy.splice(i, 1);
                }
            };

            // ! Hiện measure Giá trị, Dư nợ cuối
            measure.forEach((y) => {
                if (["OpeningDebtAmount", "ArisesDebtAmount", "PaidDebtAmount", "ClosingDebtAmount"].includes(y.measureField)) {
                    renewMeasure.push(y)
                }
            })
        }

        // ! Hiện group by Nhà cung cấp
        let findItemAdd = groupBy.findIndex(y => y.groupPropertyField === "SupplierId");
        renewGroupBy.push(groupBy[findItemAdd]);

        renewGroupBy = removeDuplicatesByField(renewGroupBy, "id");

        return {
            parentId: 0,
            groupByNew: renewGroupBy,
            measureNew: renewMeasure,
            filters: renewFilterTag,
            currentItem: newQuery,
            rename: "ProductCategoryId"
        };
    }
}
class BackCustomerId implements Strategy {
    handler(args: any) {
        const {
            groupBySelected,
            groupBy,
            filterTag,
            measure
        } = args;

        let renewGroupBy: any = JSON.parse(JSON.stringify([...groupBySelected]));
        let renewMeasure: any = [];
        let renewFilterTag: any = JSON.parse(JSON.stringify([...filterTag]));
        let newQuery: any = [];

        const removeGroupBy = ["TranDate", "RefId", "TypeId"];

        for (let i = renewGroupBy.length - 1; i >= 0; i--) {
            if (removeGroupBy.includes(renewGroupBy[i].groupPropertyField)) {
                renewGroupBy.splice(i, 1);
            }
        };

        // ! Hiện measure Giá trị, Dư nợ cuối
        measure.forEach((y) => {
            if (["OpeningDebtAmount", "ArisesDebtAmount", "PaidDebtAmount", "ClosingDebtAmount"].includes(y.measureField)) {
                renewMeasure.push(y)
            }
        })

        // ! Hiện group by Customer
        let findItemAdd = groupBy.findIndex(y => y.groupPropertyField === "CustomerId");
        renewGroupBy.push(groupBy[findItemAdd]);

        renewGroupBy = removeDuplicatesByField(renewGroupBy, "id");

        return {
            parentId: 0,
            groupByNew: renewGroupBy,
            measureNew: renewMeasure,
            filters: renewFilterTag,
            currentItem: newQuery,
            rename: "CustomerId"
        };
    }
}

const DrilldownStategy = new DrilldownStategyPattern();

// Mở Drilldown cho ngành hàng
DrilldownStategy.use(DrilldownNameAction.ProductCategoryId_open, new OpenProductCategoryIdStrategy());

// Mở Drilldown cho nhà cung cấp
DrilldownStategy.use(DrilldownNameAction.SupplierId_open, new OpenSupplierIdStrategy());

// Mở Drilldown cho khách hàng
DrilldownStategy.use(DrilldownNameAction.CustomerId_open, new OpenCustomerIdStrategy());

// Back Drilldown cho ngành hàng
DrilldownStategy.use(DrilldownNameAction.ProductCategoryId_back, new BackProductCategoryId())

// Back Drilldown cho nhà cung cấp
DrilldownStategy.use(DrilldownNameAction.SupplierId_back, new BackSupplierId());

// Back Drilldown cho khách hàng
DrilldownStategy.use(DrilldownNameAction.CustomerId_back, new BackCustomerId());

export function drilldownHandler(mode: DrillDownName, action: DrilldownAction, args: any) {
    return DrilldownStategy.handler(mode, action, args)
}
